enum MODAL_FILTERS {
  BUDGET = 'budget',
  DURATION = 'duration',
  PERIOD = 'period',
  STOPOVERTOWN = 'stopover-town',
  WISH_CATEGORY = 'wishCategory',
  TYPE_CATEGORY = 'typeCategory',
  WP_CATEGORY_EVENT = 'wpCategoryEvent',
  WP_CATEGORY_ARTICLE = 'wpCategoryArticle',
  WP_CONTINENT = 'wpContinent',
  WP_COUNTRY = 'wpCountry',
  MONTHS = 'months'
}

export default MODAL_FILTERS;
